<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="card">
      <div class="card-body">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <widget
              :fornecedor="fornecedor_fantasia"
              :Cep="cep_fornecedor"
              :cidade="cidade_fornecedor"
            ></widget>
            <!-- <Multiselect v-model="select_fornecedor" :options="fornecedores" > </Multiselect> -->
            <multiselect
              v-model="select_fornecedor"
              placeholder="Selecione"
              label="pes_fantasia"
              track-by="descricao"
              :options="fornecedores"
              :option-height="104"
              :custom-label="customLabelFornecedor"
              :show-labels="false"
              deselect-label="ENTER para remover"
              select-label="Pressione ENTER"
              @select="seleciona_valores($event)"
              @change="remover_valr(1)"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.id }} -
                    {{ props.option.pes_fantasia }}</span
                  >
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.id }} -
                    {{ props.option.pes_fantasia }}</span
                  >
                </div> </template
              ><span slot="noResult">Opa! Nenhum registro encontrado</span>
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dropdown_btn" class="card">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button
          :class="{ 'green-header': dropdown_btn === true }"
          block
          v-b-toggle.fornecedor
          variant="light"
          @click="dropdown_btn_show()"
          >Informação do Fornecedor: Clique para Abrir
        </b-button>
      </b-card-header>
    </div>
    <div v-if="modal_registro" class="card">
      <registro
        @back="back"
        @post_reg_pag="post_reg_pag"
        :objeto_for="objeto_fornecedor"
        :abr_modal="modal_registro"
        :Cep="cep_fornecedor"
        :cidade="cidade_fornecedor"
        :fornecedor="fornecedor_fantasia"
        :pagamentos="pagamentos"
        :caixas="caixas"
      />
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import { http } from "../../../../helpers/easyindustriaapi/config";
import Multiselect from "vue-multiselect";
import widget from "./widget.vue";
import registro from "./registro.vue";
export default {
  data() {
    return {
      pagamento: {},
      fornecedores: [],
      titleBody: "Registro de REcebimento de Cliente",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Financeiro",
          href: "/",
          active: true,
        },
        {
          text: "Pagamento a fornecedor",
          href: "/financeiro/pagamentofornecedor",
          active: true,
        },
      ],
      select_fornecedor: null,
      cep_fornecedor: null,
      cidade_fornecedor: null,
      fornecedor_fantasia: null,
      modal_registro: false,
      dropdown_btn: false,
      objeto_fornecedor: {},
      pagamentos: [],
      caixas: [],
    };
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    widget,
    registro,
  },
  mounted() {
    //this.getFornecedores();
    this.get_pessoa_cliente()
    this.gettipoPagamento();
    this.getCaixas();
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
  },
  methods: {
    async get_pessoa_cliente() {
      // depois passar o empresa id , pq senão vai trazer de todas as empresas;
      // + "&empresa_id=" + this.currentEmpresa.id
      try {
        let response = await http.get("/cliente/?cliente_id=" + -1);
        if (response.status === 200) {
          this.fornecedores = response.data;
          //   console.log("motoristaas")
            console.log(this.fornecedores)
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.fornecedores = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    remover_valr(e) {
      console.log(e);
    },
    dropdown_btn_show() {
      this.modal_registro = true;
      this.dropdown_btn = !this.dropdown_btn;
      this.objeto_fornecedor.cep = this.cep_fornecedor;
      this.objeto_fornecedor.cidadde = this.cidade_fornecedor;
      this.objeto_fornecedor.fantasia = this.fornecedor_fantasia;
      console.log(this.objeto_fornecedor);
    },
    seleciona_valores(e) {
      //console.log(e);
      //this.modal_registro = true;
      this.dropdown_btn = true;
      this.cep_fornecedor = e.pes_cpfcnpj;
      this.cidade_fornecedor = e.pes_cidade;
      this.fornecedor_fantasia = e.pes_fantasia;
      this.objeto_fornecedor.cep = e.pes_cpfcnpj;
      this.objeto_fornecedor.cidadde = e.pes_cidade;
      this.objeto_fornecedor.fantasia = e.pes_fantasia;
      //console.log(this.objeto_fornecedor)
    },
    customLabelFornecedor({ id, pess_fantasia }) {
      return `${id} – ${pess_fantasia}`;
    },
    async getFornecedores() {
      try {
        let response = await http.get(
          "/pessoa?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.fornecedores = response.data;
          //console.log(this.fornecedores);
        } else {
          this.fornecedores = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async getCaixas() {
      try {
        let response = await http.get(
          "/movimento-caixa?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.caixas = response.data;
          //console.log(this.caixas);
        } else {
          this.caixas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async gettipoPagamento() {
      try {
        let response = await http.get(
          "/tipopagamento?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.pagamentos = response.data;
          //console.log(this.pagamentos);
        } else {
          this.pagamentos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none")
      {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block")
      {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    async getData() {
      this.onLoader();

      try {
        let response = await http.get(
          "/regPag?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.cheques = response.data;
          console.log(this.cheques);
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async post_reg_pag(pagamento) {
       pagamento.empresa_id = this.currentEmpresa.id;
      // cheque.user_id = this.currentUser.id;
      //console.log(cheque);
      let response = await http
        .post("/regPagCliente?empresa_id=" + this.currentEmpresa.id, pagamento)
        .catch((error) => {
          this.makeToast("danger", error.response);
        });
      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast("success", "Registro incluído");
      }
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    back() {
      this.modal_registro = false;
      this.select_fornecedor = null;
      this.cep_fornecedor = "selecione";
      this.cidade_fornecedor = "selecione";
      this.fornecedor_fantasia = "selecione";
    },
  },
};
</script>

<style scoped>
.green-header {
  background-color: greenyellow;
}
</style>